<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns is-multiline">
        <div class="column is-12">
          <label class="label tittle-form">Información General</label>
        </div>
        <div class="column is-6">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="control">
                <label class="label">Titulo de la entrada</label>
                <input
                  required
                  class="input"
                  type="text"
                  v-model="title"
                  name="title"
                  v-validate="'required'"
                  data-vv-as="titulo"
                  :class="{'is-danger': vverrors.has('title')}"
                >
                <p class="help is-danger"> {{vverrors.first('title')}} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="control">
            <div class="file has-name is-boxed">
              <label class="file-label full-width">
                <input required
                  class="file-input"
                  type="file"
                  name="image"
                  @change="uploadFile"
                  data-vv-as="imagen"
                  v-validate="'required|ext:jpeg,jpg,png'"
                  accept=".jpeg,.jpg,.png"
                >
                <span class="file-cta">
                <template v-if="!previewImage">
                    <span class="file-label">
                      Agregar imagen
                    </span>
                  </template>
                  <div class="content__image">
                    <img :src="previewImage" class="image__uploading">
                  </div>
                </span>
                <label class="has-text-centered" :class="{'file-name file-name-text': !fileName}">
                      {{fileName || '----'}}
                  </label>
              </label>
            </div>
            <p class="help is-danger"> {{vverrors.first('image')}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-6">
      <label class="label">Autor</label>
      <div class="control">
        <input
          required
          class="input"
          type="text"
          v-model="author"
          name="author"
          v-validate="'required'"
          data-vv-as="Autor"
          :class="{'is-danger': vverrors.has('author')}"
        >
          <p class="help is-danger"> {{vverrors.first('author')}} </p>
      </div>
    </div>
    <div class="column is-6">
      <label class="label">Categoría</label>
      <v-select
        :multiple="true"
        v-model="categoriesSelected"
        :options="categoriesList"
        name="categoryId"
        v-validate="'required'"
        data-vv-as="categoría"
      />
    </div>
    <div class="column is-12">
      <label class="label">Descripción corta</label>
      <p class="help is-info">Máximo de 170 cáracteres.</p>
      <div class="control">
        <textarea
          class="textarea"
          v-model="shortDescription"
          name="shortDescription"
          v-validate="'required|max:170'"
          data-vv-as="descripción corta"
          maxlength="170"
        ></textarea>
      </div>
      <p class="help is-danger"> {{vverrors.first('shortDescription')}} </p>
    </div>
    <div class="column is-12">
      <label class="label">Descripción larga</label>
      <div class="control">
        <vue-editor
          ref="editor"
          v-model="longDescription"
          name="longDescription"
          v-validate="'required'"
          data-vv-as="descripción larga"
        />
      </div>
      <p class="help is-danger"> {{vverrors.first('longDescription')}} </p>
    </div>
    <div class="column is-12">
      <router-link :to="{ name: 'blog' }" class="button is-danger"> Cancelar </router-link>
      <button
        class="button button-givu is-pulled-right"
        @click="createPost"
        :class="{ 'is-loading': loading }"
        :disabled="loading"
      >
        <label>Guardar</label>
      </button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions } from 'vuex'
import vselect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  components: {
    VueEditor,
    'v-select': vselect
  },
  data () {
    return {
      title: null,
      image: {},
      author: null,
      categoryId: null,
      shortDescription: null,
      longDescription: null,
      categories: [],
      fileName: null,
      previewImage: null,
      loading: false,
      categoriesSelected: [],
      categoriesList: []
    }
  },
  beforeMount () {
    this.getBlogCategories()
      .then(categoriesList => {
        this.categories = categoriesList.payload
        this.categoriesList = categoriesList.payload.reduce((accum, element) => {
          accum.push({
            id: element.id,
            label: element.name
          })
          return accum
        }, [])
      })
  },
  methods: {
    ...mapActions(['getBlogCategories', 'addPost', 'addTemporalNotification']),
    uploadFile (event) {
      let uploadFile = event.target.files[0]
      if (uploadFile.size > 1100000) {
        this.addTemporalNotification({ type: 'danger', message: 'El peso de la imagen no puede exceder 1 MB' })
      } else {
        const [ file ] = event.target.files
        this[event.target.name] = { key: event.target.name, file }
        this.fileName = file.name
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          this.previewImage = e.target.result
        }
      }
    },
    async createPost () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          title: this.title,
          image: this.image,
          author: this.author,
          categoryId: this.categoryId,
          categories: this.categoriesSelected,
          shortDescription: this.shortDescription,
          longDescription: this.longDescription
        }
        const success = await this.addPost(data)
        if (success) {
          this.$router.push({ name: 'blog' })
        }
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
  .tittle-form {
    height: 20px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.2px;
    text-align: left;
    color: #3b4982;
  }

  .full-width {
    width: 100%;
  }

  .file-name-text {
    max-width: 100% !important;
  }

  .save-button {
    border-radius: 5px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #3b5088;
  }

  .save-button label {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.2px;
    text-align: left;
    color: #ffffff;
    cursor: pointer;
  }
  textarea {
    resize: none;
  }
</style>
